import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import DividerHr from "../Elements/DividerHr"

const Layout = styled.div`
    /* IE styling */
    display: block !important;
    position: relative;
    @media screen and (min-width: 480px) {
        aside,
        section {
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
        .post__content {
            margin-top: 32px;
        }
    }
    @media (min-width: 1080px) {
        display: flex !important;
        padding-left: 70px !important;
        padding-right: 70px !important;
        flex-wrap: wrap;
        & > aside,
        & > section {
            max-width: none;
            margin: 0;
        }
        aside {
            width: 20%;
            margin-right: 8%;
        }
        section {
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            width: 72%;
            &:nth-child(odd) {
                flex-direction: row-reverse;
            }
            .post__image {
                width: 40%;
            }
            .post__content {
                width: 50%;
                margin-top: 0;
            }
        }
    }
    @supports (grid-gap: 0) {
        /* Reset IE Styling */
        display: grid !important;
        @media screen and (min-width: 480px) {
            aside,
            section {
                max-width: none;
            }
            .post__content {
                margin-top: 32px;
            }
        }
        @media (min-width: 1080px) {
            display: grid !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            & > aside,
            & > section {
                max-width: auto;
                margin: 0;
            }
            aside {
                width: 100%;
                margin-right: 0;
            }
            section {
                display: block;
                margin-left: 0;
                width: 100%;
                .post__image {
                    width: 100%;
                }
                .post__content {
                    width: 100%;
                    margin-top: 0;
                }
            }
        }
        /* Modern Browsers */
        aside {
            display: block;
            -ms-grid-row-span: 8;
            grid-row: span 8 / auto;
            -ms-grid-column: 2;
            -ms-grid-column-span: 4;
            grid-column: 2 / 6;
            @media (min-width: 768px) and (max-width: 1079px) {
                grid-column: 2 / -2;
                .wayfinder {
                    margin-top: -1.5rem;
                }
                .wayfinder__wrapper {
                    margin-top: ${props => props.theme["space-md"]};
                }
            }
        }
        section {
            display: grid;
            grid-auto-flow: dense;
            grid-template-columns: repeat(6, 1fr);
            grid-auto-rows: auto;
            grid-column: 1 / -1;
            grid-column-gap: 16px;
            opacity: 0;
            animation: fadeUpSubtle 1s ${props => props.theme["cubic-bezier"]}
                forwards;
            &:nth-child(1) {
                animation-delay: 0.12s;
            }
            &:nth-child(2) {
                animation-delay: 0.24s;
            }
            &:nth-child(3) {
                animation-delay: 0.36s;
            }
            &:nth-child(4) {
                animation-delay: 0.48s;
            }
            &:nth-child(5) {
                animation-delay: 0.6s;
            }
            &:nth-child(6) {
                animation-delay: 0.72s;
            }
            &:nth-child(7) {
                animation-delay: 0.84s;
            }
            &:nth-child(8) {
                animation-delay: 0.96s;
            }
            @media (min-width: 480px) {
                grid-column: 2 / -2;
            }
            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(24, 1fr);
                margin-bottom: 100px;
                &:nth-child(2n) > figure {
                    grid-column: 1 / 12;
                }
                &:nth-child(2n + 1) > figure {
                    grid-column: 13 / -1;
                }
                &:nth-child(2n) > div {
                    grid-column: 13 / -1;
                }
                &:nth-child(2n + 1) > div {
                    grid-column: 1 / 12;
                }
            }
            @media screen and (min-width: 1080px) {
                grid-column: 7 / -2;
                margin-bottom: 160px;
            }
            & > figure {
                grid-column: 1 / -1;
                margin: 0px;
            }
            & > div {
                grid-column: 1 / -1;
            }
        }
    }
    aside.has-divider-curve {
        padding-bottom: 0;
    }
    .title {
        border-top: 1px solid;
    }
    .wayfinder {
        text-align: center;
        @media screen and (min-width: 1080px) {
            text-align: left;
            top: 180px;
            position: sticky;
            list-style: none;
            &__headline {
                padding-top: 1rem;
                border-top: 1px solid ${props => props.theme["white"]};
            }
            &__filter {
                &:last-child {
                    margin-bottom: ${props => props.theme["space-xl"]};
                }
            }
            &__divider {
                display: none;
            }
        }
        @media screen and (min-width: 200px) and (max-width: 1079px) {
            &__wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }
            &__filter:not(:first-child):before {
                content: "•";
                padding-right: ${props => props.theme["space-xxs"]};
                padding-left: ${props => props.theme["space-xxs"]};
            }
        }
        &__filter {
            position: relative;
            cursor: pointer;
            opacity: 1;
            transition: 0.3s color ${props => props.theme["cubic-bezier"]};
            &.is-active {
                text-decoration: underline;
            }
        }
    }
    /* image zoom on hover */
    .post {
        .gatsby-image-wrapper {
            transition: transform 11s linear;
        }
        .cover-image {
            overflow: hidden;
            border-radius: 10px;
            padding-bottom: 56%;
            transition: box-shadow 0.5s ${props => props.theme["cubic-bezier"]};
        }
        &:last-child {
            padding-bottom: 0;
        }
        &:hover {
            .post__content {
                @media (min-width: 768px) {
                    opacity: 1;
                }
                &:before {
                    height: 6px;
                    background: ${props => props.theme["white"]};
                }
            }
            .gatsby-image-wrapper {
                transform: scale(1.4) rotate(-2deg);
            }
            .cover-image {
                box-shadow: 0 19px 38px rgba(0, 0, 0, 0.15),
                    0 15px 12px rgba(0, 0, 0, 0.11);
            }
        }
    }
    /* top bar animation on hover */
    .post__content {
        position: relative;
        @media (min-width: 200px) and (max-width: 767px) {
            margin-top: ${props => props.theme["space-lg"]};
        }
        @media (min-width: 768px) {
            margin-top: 0;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${props => props.theme["white"]};
            transition: all 0.24s ${props => props.theme["cubic-bezier"]};
        }
        &:hover {
            .PostCard__title {
                color: ${props => props.theme["black"]};
            }
            .cover-image {
                box-shadow: 0 19px 38px rgba(0, 0, 0, 0.15),
                    0 15px 12px rgba(0, 0, 0, 0.11);
                transition: box-shadow 0.5s
                    ${props => props.theme["cubic-bezier"]};
            }
        }
    }
    a {
        text-decoration: none;
    }
`

const PostList = ({ posts }) =>
    posts.map(({ node: post }) => (
        <section className="post pt-0" key={post.wordpress_id}>
            <figure className="post__image">
                <Link
                    to={`/${post.categories[0].slug}/${post.slug}`}
                    aria-label={`Read article ${post.title}`}
                >
                    <div className="cover-image cover-image--ratio">
                        {post.featured_media && post.featured_media.localFile && (
                            <Img
                                sizes={
                                    post.featured_media.localFile
                                        .childImageSharp.sizes
                                }
                            />
                        )}
                    </div>
                </Link>
            </figure>
            <div className="post__content">
                <Link to={`/${post.categories[0].slug}/${post.slug}`}>
                    <h2
                        className="title font-weight-bold pt-3 text-white"
                        dangerouslySetInnerHTML={{
                            __html: post.title,
                        }}
                    />
                    <div>
                        <div
                            className="p subtitle text-white"
                            dangerouslySetInnerHTML={{ __html: post.excerpt }}
                        ></div>
                    </div>
                </Link>
            </div>
        </section>
    ))
export default () => {
    const [group, setGroup] = useState("allPosts")
    const data = useStaticQuery(graphql`
        query groupedRecent {
            Medicare: allWordpressPost(
                limit: 8
                filter: {
                    categories: {
                        elemMatch: { name: { eq: "Medicare" } }
                    }
                }
            ) {
                edges {
                    node {
                        title
                        slug
                        excerpt
                        wordpress_id
                        categories {
                            name
                            slug
                        }
                        featured_media {
                            localFile {
                                childImageSharp {
                                    sizes(maxWidth: 500) {
                                        ...GatsbyImageSharpSizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
            smallBusiness: allWordpressPost(
                limit: 8
                filter: {
                    categories: {
                        elemMatch: { name: { eq: "Small Business" } }
                    }
                }
            ) {
                edges {
                    node {
                        title
                        slug
                        excerpt
                        wordpress_id
                        categories {
                            name
                            slug
                        }
                        featured_media {
                            localFile {
                                childImageSharp {
                                    sizes(maxWidth: 500) {
                                        ...GatsbyImageSharpSizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ifp: allWordpressPost(
                limit: 8
                filter: {
                    categories: {
                        elemMatch: { name: { eq: "Individual and Family" } }
                    }
                }
            ) {
                edges {
                    node {
                        title
                        slug
                        excerpt
                        wordpress_id
                        categories {
                            name
                            slug
                        }
                        featured_media {
                            localFile {
                                childImageSharp {
                                    sizes(maxWidth: 500) {
                                        ...GatsbyImageSharpSizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
            coronavirus: allWordpressPost(
                limit: 8
                filter: {
                    categories: {
                        elemMatch: { name: { eq: "Coronavirus Updates and Resources" } }
                    }
                }
            ) {
                edges {
                    node {
                        title
                        slug
                        excerpt
                        wordpress_id
                        categories {
                            name
                            slug
                        }
                        featured_media {
                            localFile {
                                childImageSharp {
                                    sizes(maxWidth: 500) {
                                        ...GatsbyImageSharpSizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
            aca: allWordpressPost(
                limit: 8
                filter: {
                    categories: {
                        elemMatch: { name: { eq: "Affordable Care Act" } }
                    }
                }
            ) {
                edges {
                    node {
                        title
                        slug
                        excerpt
                        wordpress_id
                        categories {
                            name
                            slug
                        }
                        featured_media {
                            localFile {
                                childImageSharp {
                                    sizes(maxWidth: 500) {
                                        ...GatsbyImageSharpSizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
            allPosts: allWordpressPost(limit: 8) {
                edges {
                    node {
                        title
                        slug
                        excerpt
                        wordpress_id
                        categories {
                            name
                            slug
                        }
                        featured_media {
                            localFile {
                                childImageSharp {
                                    sizes(maxWidth: 500) {
                                        ...GatsbyImageSharpSizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <section className="relative bg-green">
            <span data-wa-depth="general:overview:post selection depthTracking" />
            <Layout className="container container--grid text-white py-xl laptop:py-xxxl">
                <aside className="has-divider-curve">
                    <div className="wayfinder">
                        <h2 className="wayfinder__headline font-weight-bold text-green-text">
                            Latest News
                        </h2>
                        <div className="wayfinder__wrapper">
                            <h3
                                className={`wayfinder__filter h5 text-green-white${
                                    group === "coronavirus" ? " is-active" : ""
                                }`}
                                onClick={() => setGroup("coronavirus")}
                                data-wa-link="post selection:Coronavirus Updates"
                            >
                                Coronavirus Updates
                            </h3>
                            <h3
                                className={`wayfinder__filter h5 text-green-white${
                                    group === "aca" ? " is-active" : ""
                                }`}
                                onClick={() => setGroup("aca")}
                                data-wa-link="post selection:Affordable Care Act"
                            >
                                Affordable Care Act
                            </h3>
                            <h3
                                className={`wayfinder__filter h5 text-green-white${
                                    group === "ifp" ? " is-active" : ""
                                }`}
                                onClick={() => setGroup("ifp")}
                                data-wa-link="post selection:Individuals & Families"
                            >
                                Individuals & Families
                            </h3>
                            <h3
                                className={`wayfinder__filter h5 text-green-white${
                                    group === "smallBusiness"
                                        ? " is-active"
                                        : ""
                                }`}
                                onClick={() => setGroup("smallBusiness")}
                                data-wa-link="post selection:Small Business"
                            >
                                Small Business
                            </h3>
                            <h3
                                className={`wayfinder__filter h5 text-green-white${
                                    group === "Medicare"
                                        ? " is-active"
                                        : ""
                                }`}
                                onClick={() => setGroup("Medicare")}
                                data-wa-link="post selection:Medicare"
                            >
                                Medicare
                            </h3>
                        </div>
                    </div>
                    <div className="wayfinder__divider pt-3 pb-5">
                        <DividerHr cssColor="green-text" />
                    </div>
                </aside>

                <PostList posts={data[group].edges} />
            </Layout>
        </section>
    )
}
