import React from "react"

export default ({ cssColor = "green" }) => (
    <div
        className={`bg-${cssColor}`}
        style={{
            width: `40px`,
            height: `6px`,
            margin: "0 auto",
        }}
    />
)
