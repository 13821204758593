import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Link, navigate } from 'gatsby'
const ArticleSearchBar = styled.div`
    position: relative;
    .search-form {
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
        border-radius: 6px;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
        @media screen and (max-width: 767px) {
            width: 100%;
            flex-direction: column;
            box-shadow: none;
        }
        &__input {
            width: 320px;
            border-radius: 6px 0 0 6px;
            border-right: none;
            border-color: #bbb;
            outline: 0;
            font-size: 1rem;
            @media screen and (max-width: 767px) {
                border-radius: 6px;
                border-right: 1px solid #bbb;
                margin-bottom: 1rem;
                width: 100%;
            }
        }
        &__button {
            display: inline-block;
            width: 220px;
            border-radius: 0 6px 6px 0;
            border: none;
            @media screen and (max-width: 767px) {
                border-radius: 6px;
                width: 100%;
            }
        }
    }
`

export default () => {
    const [query, setQuery] = useState('');

    return (
        <ArticleSearchBar className="search-bar">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9 text-center">
                    <div className="search-form">
                        <input
                            id="email"
                            className="w-full my-lg mx-auto search-form__input"
                            type="text"
                            onChange={e => {
                                setQuery(e.target.value.replace(/[|&!*#;$=%^_/?@":~<>()+,]/g, ""));
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    navigate(`/search?keywords=${query}`)
                                }
                            }}
                            value={query}
                            autoComplete='off'
                            placeholder="Search..."
                        />
                        <Link
                            className="button-primary search-form__button"
                            to={`/search?keywords=${query}`}
                            >Search</Link>
                        
                    </div>
                </div>
            </div>
        </div>
    </ArticleSearchBar>
    )
}