import React, { useEffect } from "react"

import SEO from "@ifp/gatsby-theme-shared/src/components/SEO/seo"

import IndexHero from "../components/Sections/IndexHero"
import IndexPostsScroller from "../components/Sections/IndexPostsScroller"
import SubFooter from "../components/Common/SubFooter"

import website from "../../config/site"

const IndexPage = () => {
    const metaData = {
        title: website.siteTitle,
        metaDescription: website.siteDescription,
        date: "October 15, 2018",
        modified: "October 15, 2018",
        author: website.siteShortName,
        pageType: "resources"
    }

    useEffect(() => {
        window._waEvents.push("pageView", {
            page: {
                section: "general",
                name: "page:overview",
                language: "english",
            },
            sysEnv: {
                siteGroup: "rc",
            },
        })
    })

    return (
        <>
            <SEO {...metaData} />
            <div className="ehi-header--spacer"></div>
            <IndexHero></IndexHero>
            <IndexPostsScroller />
            <SubFooter
                fromPage="/resources"
                topMessage="Find your perfect health plan"
                headline="Browse from over 10,000+ health plans with just your zip code."
                cta="quote"
            />
        </>
    )
}

export default IndexPage
