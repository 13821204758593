import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import QuoteForm from "./QuoteForm"

const SubFooter = styled.section`
    position: relative;
    overflow-y: hidden;
    text-align: center;
    padding-bottom: 0;
    .cta-img {
        max-width: 100%;
        @media (min-width: 432px) and (max-width: 847px) {
            max-width: 400px;
        }
    }
    @media (min-width: 768px) {
        text-align: left;
        .top-message {
            margin-right: var(--space-xs);
            margin-bottom: 0;
            &:after {
                right: 0;
                left: auto;
                bottom: auto;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        .cta-img {
            margin-right: -14%;
            max-width: 100%;
        }
    }
    @media (min-width: 1200px) {
        .sub-footer__call-cta {
            margin-bottom: 7.5rem;
        }
    }
`

const defaultOptions = {
    category: "Individuals and Families",
}

export default ({
    topMessage,
    headline,
    fromPage,
    category = defaultOptions.category,
}) => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "hero-ifp.png" }) {
                childImageSharp {
                    fluid(maxWidth: 540) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <SubFooter className={category}>
            <span data-wa-depth="general:overview:browse plans depthTracking" />
            <div className="container">
                <div className="row align-items-end no-gutters">
                    <div className="col-xs-9 col-xl-6 mx-auto">
                        <div className="d-flex align-items-center flex-column flex-md-row">
                            <h4 className="top-message">
                                {topMessage ? topMessage : category}
                            </h4>
                            <div
                                className="bg-green"
                                style={{
                                    width: `40px`,
                                    height: `4px`,
                                }}
                            />
                        </div>

                        <h1 className="display-4 mt-4 mb-5 mx-auto">
                            {headline}
                        </h1>
                        <QuoteForm
                            fromPage="resources"
                            censusPage="Individual and Family"
                        />
                        <p className="sub-footer__call-cta pt-3">
                            Get help shopping for insurance.
                            <br />
                            Talk with a friendly agent
                            <a
                                href="tel:+1-855-844-9308"
                                className="invoca-phone-number ml-2"
                            >
                                (855) 844-9308
                            </a>
                            .
                        </p>
                    </div>
                    <div className="col-xs-9 col-xl-6">
                        {/* <svg
                            className="absolute d-none d-md-block"
                            xmlns="http://www.w3.org/2000/svg"
                            width="214"
                            height="439"
                            viewBox="0 0 214 439"
                            style={{
                                right: "0",
                                top: "0",
                            }}
                        >
                            <g fill="#075f41" fillRule="nonzero">
                                <g transform="translate(0 .5)">
                                    <circle cx="2" cy="212" r="2" />
                                    <circle cx="114" cy="212" r="2" />
                                    <circle cx="142" cy="212" r="2" />
                                    <circle cx="156" cy="212" r="2" />
                                    <circle cx="128" cy="212" r="2" />
                                    <circle cx="170" cy="212" r="2" />
                                    <circle cx="198" cy="212" r="2" />
                                    <circle cx="212" cy="212" r="2" />
                                    <circle cx="184" cy="212" r="2" />
                                    <circle cx="30" cy="212" r="2" />
                                    <circle cx="44" cy="212" r="2" />
                                    <circle cx="16" cy="212" r="2" />
                                    <circle cx="58" cy="212" r="2" />
                                    <circle cx="86" cy="212" r="2" />
                                    <circle cx="100" cy="212" r="2" />
                                    <circle cx="72" cy="212" r="2" />
                                    <circle cx="2" cy="198" r="2" />
                                    <circle cx="114" cy="198" r="2" />
                                    <circle cx="142" cy="198" r="2" />
                                    <circle cx="156" cy="198" r="2" />
                                    <circle cx="128" cy="198" r="2" />
                                    <circle cx="170" cy="198" r="2" />
                                    <circle cx="198" cy="198" r="2" />
                                    <circle cx="212" cy="198" r="2" />
                                    <circle cx="184" cy="198" r="2" />
                                    <circle cx="30" cy="198" r="2" />
                                    <circle cx="44" cy="198" r="2" />
                                    <circle cx="16" cy="198" r="2" />
                                    <circle cx="58" cy="198" r="2" />
                                    <circle cx="86" cy="198" r="2" />
                                    <circle cx="100" cy="198" r="2" />
                                    <circle cx="72" cy="198" r="2" />
                                    <circle cx="2" cy="184" r="2" />
                                    <circle cx="114" cy="184" r="2" />
                                    <circle cx="142" cy="184" r="2" />
                                    <circle cx="156" cy="184" r="2" />
                                    <circle cx="128" cy="184" r="2" />
                                    <circle cx="170" cy="184" r="2" />
                                    <circle cx="198" cy="184" r="2" />
                                    <circle cx="212" cy="184" r="2" />
                                    <circle cx="184" cy="184" r="2" />
                                    <circle cx="30" cy="184" r="2" />
                                    <circle cx="44" cy="184" r="2" />
                                    <circle cx="16" cy="184" r="2" />
                                    <circle cx="58" cy="184" r="2" />
                                    <circle cx="86" cy="184" r="2" />
                                    <circle cx="100" cy="184" r="2" />
                                    <circle cx="72" cy="184" r="2" />
                                    <circle cx="2" cy="170" r="2" />
                                    <circle cx="114" cy="170" r="2" />
                                    <circle cx="142" cy="170" r="2" />
                                    <circle cx="156" cy="170" r="2" />
                                    <circle cx="128" cy="170" r="2" />
                                    <circle cx="170" cy="170" r="2" />
                                    <circle cx="198" cy="170" r="2" />
                                    <circle cx="212" cy="170" r="2" />
                                    <circle cx="184" cy="170" r="2" />
                                    <circle cx="30" cy="170" r="2" />
                                    <circle cx="44" cy="170" r="2" />
                                    <circle cx="16" cy="170" r="2" />
                                    <circle cx="58" cy="170" r="2" />
                                    <circle cx="86" cy="170" r="2" />
                                    <circle cx="100" cy="170" r="2" />
                                    <circle cx="72" cy="170" r="2" />
                                    <circle cx="2" cy="156" r="2" />
                                    <circle cx="114" cy="156" r="2" />
                                    <circle cx="142" cy="156" r="2" />
                                    <circle cx="156" cy="156" r="2" />
                                    <circle cx="128" cy="156" r="2" />
                                    <circle cx="170" cy="156" r="2" />
                                    <circle cx="198" cy="156" r="2" />
                                    <circle cx="212" cy="156" r="2" />
                                    <circle cx="184" cy="156" r="2" />
                                    <circle cx="30" cy="156" r="2" />
                                    <circle cx="44" cy="156" r="2" />
                                    <circle cx="16" cy="156" r="2" />
                                    <circle cx="58" cy="156" r="2" />
                                    <circle cx="86" cy="156" r="2" />
                                    <circle cx="100" cy="156" r="2" />
                                    <circle cx="72" cy="156" r="2" />
                                    <circle cx="2" cy="142" r="2" />
                                    <circle cx="114" cy="142" r="2" />
                                    <circle cx="142" cy="142" r="2" />
                                    <circle cx="156" cy="142" r="2" />
                                    <circle cx="128" cy="142" r="2" />
                                    <circle cx="170" cy="142" r="2" />
                                    <circle cx="198" cy="142" r="2" />
                                    <circle cx="212" cy="142" r="2" />
                                    <circle cx="184" cy="142" r="2" />
                                    <circle cx="30" cy="142" r="2" />
                                    <circle cx="44" cy="142" r="2" />
                                    <circle cx="16" cy="142" r="2" />
                                    <circle cx="58" cy="142" r="2" />
                                    <circle cx="86" cy="142" r="2" />
                                    <circle cx="100" cy="142" r="2" />
                                    <circle cx="72" cy="142" r="2" />
                                    <circle cx="2" cy="128" r="2" />
                                    <circle cx="114" cy="128" r="2" />
                                    <circle cx="142" cy="128" r="2" />
                                    <circle cx="156" cy="128" r="2" />
                                    <circle cx="128" cy="128" r="2" />
                                    <circle cx="170" cy="128" r="2" />
                                    <circle cx="198" cy="128" r="2" />
                                    <circle cx="212" cy="128" r="2" />
                                    <circle cx="184" cy="128" r="2" />
                                    <circle cx="30" cy="128" r="2" />
                                    <circle cx="44" cy="128" r="2" />
                                    <circle cx="16" cy="128" r="2" />
                                    <circle cx="58" cy="128" r="2" />
                                    <circle cx="86" cy="128" r="2" />
                                    <circle cx="100" cy="128" r="2" />
                                    <circle cx="72" cy="128" r="2" />
                                    <circle cx="2" cy="114" r="2" />
                                    <circle cx="114" cy="114" r="2" />
                                    <circle cx="142" cy="114" r="2" />
                                    <circle cx="156" cy="114" r="2" />
                                    <circle cx="128" cy="114" r="2" />
                                    <circle cx="170" cy="114" r="2" />
                                    <circle cx="198" cy="114" r="2" />
                                    <circle cx="212" cy="114" r="2" />
                                    <circle cx="184" cy="114" r="2" />
                                    <circle cx="30" cy="114" r="2" />
                                    <circle cx="44" cy="114" r="2" />
                                    <circle cx="16" cy="114" r="2" />
                                    <circle cx="58" cy="114" r="2" />
                                    <circle cx="86" cy="114" r="2" />
                                    <circle cx="100" cy="114" r="2" />
                                    <circle cx="72" cy="114" r="2" />
                                    <circle cx="2" cy="100" r="2" />
                                    <circle cx="114" cy="100" r="2" />
                                    <circle cx="142" cy="100" r="2" />
                                    <circle cx="156" cy="100" r="2" />
                                    <circle cx="128" cy="100" r="2" />
                                    <circle cx="170" cy="100" r="2" />
                                    <circle cx="198" cy="100" r="2" />
                                    <circle cx="212" cy="100" r="2" />
                                    <circle cx="184" cy="100" r="2" />
                                    <circle cx="30" cy="100" r="2" />
                                    <circle cx="44" cy="100" r="2" />
                                    <circle cx="16" cy="100" r="2" />
                                    <circle cx="58" cy="100" r="2" />
                                    <circle cx="86" cy="100" r="2" />
                                    <circle cx="100" cy="100" r="2" />
                                    <circle cx="72" cy="100" r="2" />
                                    <circle cx="2" cy="86" r="2" />
                                    <circle cx="114" cy="86" r="2" />
                                    <circle cx="142" cy="86" r="2" />
                                    <circle cx="156" cy="86" r="2" />
                                    <circle cx="128" cy="86" r="2" />
                                    <circle cx="170" cy="86" r="2" />
                                    <circle cx="198" cy="86" r="2" />
                                    <circle cx="212" cy="86" r="2" />
                                    <circle cx="184" cy="86" r="2" />
                                    <circle cx="30" cy="86" r="2" />
                                    <circle cx="44" cy="86" r="2" />
                                    <circle cx="16" cy="86" r="2" />
                                    <circle cx="58" cy="86" r="2" />
                                    <circle cx="86" cy="86" r="2" />
                                    <circle cx="100" cy="86" r="2" />
                                    <circle cx="72" cy="86" r="2" />
                                    <circle cx="2" cy="72" r="2" />
                                    <circle cx="114" cy="72" r="2" />
                                    <circle cx="142" cy="72" r="2" />
                                    <circle cx="156" cy="72" r="2" />
                                    <circle cx="128" cy="72" r="2" />
                                    <circle cx="170" cy="72" r="2" />
                                    <circle cx="198" cy="72" r="2" />
                                    <circle cx="212" cy="72" r="2" />
                                    <circle cx="184" cy="72" r="2" />
                                    <circle cx="30" cy="72" r="2" />
                                    <circle cx="44" cy="72" r="2" />
                                    <circle cx="16" cy="72" r="2" />
                                    <circle cx="58" cy="72" r="2" />
                                    <circle cx="86" cy="72" r="2" />
                                    <circle cx="100" cy="72" r="2" />
                                    <circle cx="72" cy="72" r="2" />
                                    <circle cx="2" cy="58" r="2" />
                                    <circle cx="114" cy="58" r="2" />
                                    <circle cx="142" cy="58" r="2" />
                                    <circle cx="156" cy="58" r="2" />
                                    <circle cx="128" cy="58" r="2" />
                                    <circle cx="170" cy="58" r="2" />
                                    <circle cx="198" cy="58" r="2" />
                                    <circle cx="212" cy="58" r="2" />
                                    <circle cx="184" cy="58" r="2" />
                                    <circle cx="30" cy="58" r="2" />
                                    <circle cx="44" cy="58" r="2" />
                                    <circle cx="16" cy="58" r="2" />
                                    <circle cx="58" cy="58" r="2" />
                                    <circle cx="86" cy="58" r="2" />
                                    <circle cx="100" cy="58" r="2" />
                                    <circle cx="72" cy="58" r="2" />
                                    <circle cx="2" cy="44" r="2" />
                                    <circle cx="114" cy="44" r="2" />
                                    <circle cx="142" cy="44" r="2" />
                                    <circle cx="156" cy="44" r="2" />
                                    <circle cx="128" cy="44" r="2" />
                                    <circle cx="170" cy="44" r="2" />
                                    <circle cx="198" cy="44" r="2" />
                                    <circle cx="212" cy="44" r="2" />
                                    <circle cx="184" cy="44" r="2" />
                                    <circle cx="30" cy="44" r="2" />
                                    <circle cx="44" cy="44" r="2" />
                                    <circle cx="16" cy="44" r="2" />
                                    <circle cx="58" cy="44" r="2" />
                                    <circle cx="86" cy="44" r="2" />
                                    <circle cx="100" cy="44" r="2" />
                                    <circle cx="72" cy="44" r="2" />
                                    <circle cx="2" cy="30" r="2" />
                                    <circle cx="114" cy="30" r="2" />
                                    <circle cx="142" cy="30" r="2" />
                                    <circle cx="156" cy="30" r="2" />
                                    <circle cx="128" cy="30" r="2" />
                                    <circle cx="170" cy="30" r="2" />
                                    <circle cx="198" cy="30" r="2" />
                                    <circle cx="212" cy="30" r="2" />
                                    <circle cx="184" cy="30" r="2" />
                                    <circle cx="30" cy="30" r="2" />
                                    <circle cx="44" cy="30" r="2" />
                                    <circle cx="16" cy="30" r="2" />
                                    <circle cx="58" cy="30" r="2" />
                                    <circle cx="86" cy="30" r="2" />
                                    <circle cx="100" cy="30" r="2" />
                                    <circle cx="72" cy="30" r="2" />
                                    <circle cx="2" cy="16" r="2" />
                                    <circle cx="114" cy="16" r="2" />
                                    <circle cx="142" cy="16" r="2" />
                                    <circle cx="156" cy="16" r="2" />
                                    <circle cx="128" cy="16" r="2" />
                                    <circle cx="170" cy="16" r="2" />
                                    <circle cx="198" cy="16" r="2" />
                                    <circle cx="212" cy="16" r="2" />
                                    <circle cx="184" cy="16" r="2" />
                                    <circle cx="30" cy="16" r="2" />
                                    <circle cx="44" cy="16" r="2" />
                                    <circle cx="16" cy="16" r="2" />
                                    <circle cx="58" cy="16" r="2" />
                                    <circle cx="86" cy="16" r="2" />
                                    <circle cx="100" cy="16" r="2" />
                                    <circle cx="72" cy="16" r="2" />
                                    <circle cx="2" cy="2" r="2" />
                                    <circle cx="114" cy="2" r="2" />
                                    <circle cx="142" cy="2" r="2" />
                                    <circle cx="156" cy="2" r="2" />
                                    <circle cx="128" cy="2" r="2" />
                                    <circle cx="170" cy="2" r="2" />
                                    <circle cx="198" cy="2" r="2" />
                                    <circle cx="212" cy="2" r="2" />
                                    <circle cx="184" cy="2" r="2" />
                                    <circle cx="30" cy="2" r="2" />
                                    <circle cx="44" cy="2" r="2" />
                                    <circle cx="16" cy="2" r="2" />
                                    <circle cx="58" cy="2" r="2" />
                                    <circle cx="86" cy="2" r="2" />
                                    <circle cx="100" cy="2" r="2" />
                                    <circle cx="72" cy="2" r="2" />
                                </g>
                                <g transform="translate(0 224.5)">
                                    <circle cx="2" cy="212" r="2" />
                                    <circle cx="114" cy="212" r="2" />
                                    <circle cx="142" cy="212" r="2" />
                                    <circle cx="156" cy="212" r="2" />
                                    <circle cx="128" cy="212" r="2" />
                                    <circle cx="170" cy="212" r="2" />
                                    <circle cx="198" cy="212" r="2" />
                                    <circle cx="212" cy="212" r="2" />
                                    <circle cx="184" cy="212" r="2" />
                                    <circle cx="30" cy="212" r="2" />
                                    <circle cx="44" cy="212" r="2" />
                                    <circle cx="16" cy="212" r="2" />
                                    <circle cx="58" cy="212" r="2" />
                                    <circle cx="86" cy="212" r="2" />
                                    <circle cx="100" cy="212" r="2" />
                                    <circle cx="72" cy="212" r="2" />
                                    <circle cx="2" cy="198" r="2" />
                                    <circle cx="114" cy="198" r="2" />
                                    <circle cx="142" cy="198" r="2" />
                                    <circle cx="156" cy="198" r="2" />
                                    <circle cx="128" cy="198" r="2" />
                                    <circle cx="170" cy="198" r="2" />
                                    <circle cx="198" cy="198" r="2" />
                                    <circle cx="212" cy="198" r="2" />
                                    <circle cx="184" cy="198" r="2" />
                                    <circle cx="30" cy="198" r="2" />
                                    <circle cx="44" cy="198" r="2" />
                                    <circle cx="16" cy="198" r="2" />
                                    <circle cx="58" cy="198" r="2" />
                                    <circle cx="86" cy="198" r="2" />
                                    <circle cx="100" cy="198" r="2" />
                                    <circle cx="72" cy="198" r="2" />
                                    <circle cx="2" cy="184" r="2" />
                                    <circle cx="114" cy="184" r="2" />
                                    <circle cx="142" cy="184" r="2" />
                                    <circle cx="156" cy="184" r="2" />
                                    <circle cx="128" cy="184" r="2" />
                                    <circle cx="170" cy="184" r="2" />
                                    <circle cx="198" cy="184" r="2" />
                                    <circle cx="212" cy="184" r="2" />
                                    <circle cx="184" cy="184" r="2" />
                                    <circle cx="30" cy="184" r="2" />
                                    <circle cx="44" cy="184" r="2" />
                                    <circle cx="16" cy="184" r="2" />
                                    <circle cx="58" cy="184" r="2" />
                                    <circle cx="86" cy="184" r="2" />
                                    <circle cx="100" cy="184" r="2" />
                                    <circle cx="72" cy="184" r="2" />
                                    <circle cx="2" cy="170" r="2" />
                                    <circle cx="114" cy="170" r="2" />
                                    <circle cx="142" cy="170" r="2" />
                                    <circle cx="156" cy="170" r="2" />
                                    <circle cx="128" cy="170" r="2" />
                                    <circle cx="170" cy="170" r="2" />
                                    <circle cx="198" cy="170" r="2" />
                                    <circle cx="212" cy="170" r="2" />
                                    <circle cx="184" cy="170" r="2" />
                                    <circle cx="30" cy="170" r="2" />
                                    <circle cx="44" cy="170" r="2" />
                                    <circle cx="16" cy="170" r="2" />
                                    <circle cx="58" cy="170" r="2" />
                                    <circle cx="86" cy="170" r="2" />
                                    <circle cx="100" cy="170" r="2" />
                                    <circle cx="72" cy="170" r="2" />
                                    <circle cx="2" cy="156" r="2" />
                                    <circle cx="114" cy="156" r="2" />
                                    <circle cx="142" cy="156" r="2" />
                                    <circle cx="156" cy="156" r="2" />
                                    <circle cx="128" cy="156" r="2" />
                                    <circle cx="170" cy="156" r="2" />
                                    <circle cx="198" cy="156" r="2" />
                                    <circle cx="212" cy="156" r="2" />
                                    <circle cx="184" cy="156" r="2" />
                                    <circle cx="30" cy="156" r="2" />
                                    <circle cx="44" cy="156" r="2" />
                                    <circle cx="16" cy="156" r="2" />
                                    <circle cx="58" cy="156" r="2" />
                                    <circle cx="86" cy="156" r="2" />
                                    <circle cx="100" cy="156" r="2" />
                                    <circle cx="72" cy="156" r="2" />
                                    <circle cx="2" cy="142" r="2" />
                                    <circle cx="114" cy="142" r="2" />
                                    <circle cx="142" cy="142" r="2" />
                                    <circle cx="156" cy="142" r="2" />
                                    <circle cx="128" cy="142" r="2" />
                                    <circle cx="170" cy="142" r="2" />
                                    <circle cx="198" cy="142" r="2" />
                                    <circle cx="212" cy="142" r="2" />
                                    <circle cx="184" cy="142" r="2" />
                                    <circle cx="30" cy="142" r="2" />
                                    <circle cx="44" cy="142" r="2" />
                                    <circle cx="16" cy="142" r="2" />
                                    <circle cx="58" cy="142" r="2" />
                                    <circle cx="86" cy="142" r="2" />
                                    <circle cx="100" cy="142" r="2" />
                                    <circle cx="72" cy="142" r="2" />
                                    <circle cx="2" cy="128" r="2" />
                                    <circle cx="114" cy="128" r="2" />
                                    <circle cx="142" cy="128" r="2" />
                                    <circle cx="156" cy="128" r="2" />
                                    <circle cx="128" cy="128" r="2" />
                                    <circle cx="170" cy="128" r="2" />
                                    <circle cx="198" cy="128" r="2" />
                                    <circle cx="212" cy="128" r="2" />
                                    <circle cx="184" cy="128" r="2" />
                                    <circle cx="30" cy="128" r="2" />
                                    <circle cx="44" cy="128" r="2" />
                                    <circle cx="16" cy="128" r="2" />
                                    <circle cx="58" cy="128" r="2" />
                                    <circle cx="86" cy="128" r="2" />
                                    <circle cx="100" cy="128" r="2" />
                                    <circle cx="72" cy="128" r="2" />
                                    <circle cx="2" cy="114" r="2" />
                                    <circle cx="114" cy="114" r="2" />
                                    <circle cx="142" cy="114" r="2" />
                                    <circle cx="156" cy="114" r="2" />
                                    <circle cx="128" cy="114" r="2" />
                                    <circle cx="170" cy="114" r="2" />
                                    <circle cx="198" cy="114" r="2" />
                                    <circle cx="212" cy="114" r="2" />
                                    <circle cx="184" cy="114" r="2" />
                                    <circle cx="30" cy="114" r="2" />
                                    <circle cx="44" cy="114" r="2" />
                                    <circle cx="16" cy="114" r="2" />
                                    <circle cx="58" cy="114" r="2" />
                                    <circle cx="86" cy="114" r="2" />
                                    <circle cx="100" cy="114" r="2" />
                                    <circle cx="72" cy="114" r="2" />
                                    <circle cx="2" cy="100" r="2" />
                                    <circle cx="114" cy="100" r="2" />
                                    <circle cx="142" cy="100" r="2" />
                                    <circle cx="156" cy="100" r="2" />
                                    <circle cx="128" cy="100" r="2" />
                                    <circle cx="170" cy="100" r="2" />
                                    <circle cx="198" cy="100" r="2" />
                                    <circle cx="212" cy="100" r="2" />
                                    <circle cx="184" cy="100" r="2" />
                                    <circle cx="30" cy="100" r="2" />
                                    <circle cx="44" cy="100" r="2" />
                                    <circle cx="16" cy="100" r="2" />
                                    <circle cx="58" cy="100" r="2" />
                                    <circle cx="86" cy="100" r="2" />
                                    <circle cx="100" cy="100" r="2" />
                                    <circle cx="72" cy="100" r="2" />
                                    <circle cx="2" cy="86" r="2" />
                                    <circle cx="114" cy="86" r="2" />
                                    <circle cx="142" cy="86" r="2" />
                                    <circle cx="156" cy="86" r="2" />
                                    <circle cx="128" cy="86" r="2" />
                                    <circle cx="170" cy="86" r="2" />
                                    <circle cx="198" cy="86" r="2" />
                                    <circle cx="212" cy="86" r="2" />
                                    <circle cx="184" cy="86" r="2" />
                                    <circle cx="30" cy="86" r="2" />
                                    <circle cx="44" cy="86" r="2" />
                                    <circle cx="16" cy="86" r="2" />
                                    <circle cx="58" cy="86" r="2" />
                                    <circle cx="86" cy="86" r="2" />
                                    <circle cx="100" cy="86" r="2" />
                                    <circle cx="72" cy="86" r="2" />
                                    <circle cx="2" cy="72" r="2" />
                                    <circle cx="114" cy="72" r="2" />
                                    <circle cx="142" cy="72" r="2" />
                                    <circle cx="156" cy="72" r="2" />
                                    <circle cx="128" cy="72" r="2" />
                                    <circle cx="170" cy="72" r="2" />
                                    <circle cx="198" cy="72" r="2" />
                                    <circle cx="212" cy="72" r="2" />
                                    <circle cx="184" cy="72" r="2" />
                                    <circle cx="30" cy="72" r="2" />
                                    <circle cx="44" cy="72" r="2" />
                                    <circle cx="16" cy="72" r="2" />
                                    <circle cx="58" cy="72" r="2" />
                                    <circle cx="86" cy="72" r="2" />
                                    <circle cx="100" cy="72" r="2" />
                                    <circle cx="72" cy="72" r="2" />
                                    <circle cx="2" cy="58" r="2" />
                                    <circle cx="114" cy="58" r="2" />
                                    <circle cx="142" cy="58" r="2" />
                                    <circle cx="156" cy="58" r="2" />
                                    <circle cx="128" cy="58" r="2" />
                                    <circle cx="170" cy="58" r="2" />
                                    <circle cx="198" cy="58" r="2" />
                                    <circle cx="212" cy="58" r="2" />
                                    <circle cx="184" cy="58" r="2" />
                                    <circle cx="30" cy="58" r="2" />
                                    <circle cx="44" cy="58" r="2" />
                                    <circle cx="16" cy="58" r="2" />
                                    <circle cx="58" cy="58" r="2" />
                                    <circle cx="86" cy="58" r="2" />
                                    <circle cx="100" cy="58" r="2" />
                                    <circle cx="72" cy="58" r="2" />
                                    <circle cx="2" cy="44" r="2" />
                                    <circle cx="114" cy="44" r="2" />
                                    <circle cx="142" cy="44" r="2" />
                                    <circle cx="156" cy="44" r="2" />
                                    <circle cx="128" cy="44" r="2" />
                                    <circle cx="170" cy="44" r="2" />
                                    <circle cx="198" cy="44" r="2" />
                                    <circle cx="212" cy="44" r="2" />
                                    <circle cx="184" cy="44" r="2" />
                                    <circle cx="30" cy="44" r="2" />
                                    <circle cx="44" cy="44" r="2" />
                                    <circle cx="16" cy="44" r="2" />
                                    <circle cx="58" cy="44" r="2" />
                                    <circle cx="86" cy="44" r="2" />
                                    <circle cx="100" cy="44" r="2" />
                                    <circle cx="72" cy="44" r="2" />
                                    <circle cx="2" cy="30" r="2" />
                                    <circle cx="114" cy="30" r="2" />
                                    <circle cx="142" cy="30" r="2" />
                                    <circle cx="156" cy="30" r="2" />
                                    <circle cx="128" cy="30" r="2" />
                                    <circle cx="170" cy="30" r="2" />
                                    <circle cx="198" cy="30" r="2" />
                                    <circle cx="212" cy="30" r="2" />
                                    <circle cx="184" cy="30" r="2" />
                                    <circle cx="30" cy="30" r="2" />
                                    <circle cx="44" cy="30" r="2" />
                                    <circle cx="16" cy="30" r="2" />
                                    <circle cx="58" cy="30" r="2" />
                                    <circle cx="86" cy="30" r="2" />
                                    <circle cx="100" cy="30" r="2" />
                                    <circle cx="72" cy="30" r="2" />
                                    <circle cx="2" cy="16" r="2" />
                                    <circle cx="114" cy="16" r="2" />
                                    <circle cx="142" cy="16" r="2" />
                                    <circle cx="156" cy="16" r="2" />
                                    <circle cx="128" cy="16" r="2" />
                                    <circle cx="170" cy="16" r="2" />
                                    <circle cx="198" cy="16" r="2" />
                                    <circle cx="212" cy="16" r="2" />
                                    <circle cx="184" cy="16" r="2" />
                                    <circle cx="30" cy="16" r="2" />
                                    <circle cx="44" cy="16" r="2" />
                                    <circle cx="16" cy="16" r="2" />
                                    <circle cx="58" cy="16" r="2" />
                                    <circle cx="86" cy="16" r="2" />
                                    <circle cx="100" cy="16" r="2" />
                                    <circle cx="72" cy="16" r="2" />
                                    <circle cx="2" cy="2" r="2" />
                                    <circle cx="114" cy="2" r="2" />
                                    <circle cx="142" cy="2" r="2" />
                                    <circle cx="156" cy="2" r="2" />
                                    <circle cx="128" cy="2" r="2" />
                                    <circle cx="170" cy="2" r="2" />
                                    <circle cx="198" cy="2" r="2" />
                                    <circle cx="212" cy="2" r="2" />
                                    <circle cx="184" cy="2" r="2" />
                                    <circle cx="30" cy="2" r="2" />
                                    <circle cx="44" cy="2" r="2" />
                                    <circle cx="16" cy="2" r="2" />
                                    <circle cx="58" cy="2" r="2" />
                                    <circle cx="86" cy="2" r="2" />
                                    <circle cx="100" cy="2" r="2" />
                                    <circle cx="72" cy="2" r="2" />
                                </g>
                            </g>
                        </svg> */}
                        <Img
                            fluid={data.file.childImageSharp.fluid}
                            style={{ maxWidth: 540, marginLeft: `auto` }}
                        />
                    </div>
                </div>
            </div>
        </SubFooter>
    )
}
