import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import TextAccent from "../Elements/TextAccent"
import PostCard from "../Elements/PostCard"
import DividerCurve from "../Assets/DividerCurve"
import ArticleSearchBar from "./ArticleSearchBar"

const MainGuides = styled.div`
    @media screen and (min-width: 760px) {
        display: flex;
        [class*="PostCard-"] {
            width: calc((100% - 112px) / 3);
            &:not(:last-child) {
                margin-right: 56px;
            }
        }
    }
    @supports (grid-gap: 0) {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
        @media (min-width: 480px) and (max-width: 767px) {
            max-width: 70%;
            margin: 0 auto;
        }
        @media screen and (min-width: 768px) {
            -ms-grid-columns: (1fr) [3];
            grid-template-columns: repeat(3, 1fr);
            max-width: 100%;
            animation: fadeUpSubtle 1s var(--cubic-bezier) forwards;
        }
        @media screen and (min-width: 1024px) {
            grid-gap: 56px;
        }

        [class*="PostCard-"] {
            width: 100%;
            margin-right: 0;
        }
    }
    [class*="PostCard-"] {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: var(--gray-lighter);
            transition: all 0.24s var(--cubic-bezier);
        }
        .PostCard__title {
            color: var(--black);
        }
        &:hover {
            &:before {
                height: 6px;
                background: var(--green);
            }
            .PostCard__title {
				color: var(--black);
            }
            .PostCard__image {
                box-shadow: 0 19px 38px rgba(0, 0, 0, 0.15),
                    0 15px 12px rgba(0, 0, 0, 0.11);
                transition: box-shadow 0.5s
                    var(--cubic-bezier);
            }
        }
        .PostCard__image {
            border-radius: 10px;
            overflow: hidden;
        }
    }
`
const SearchBarWrap = styled.div`
    padding: 20px 0;
`

export default props => {
    const data = useStaticQuery(graphql`
        query MainGuides {
            allWordpressWpGuides(limit: 3) {
                edges {
                    node {
                        title
                        slug
                        wordpress_id
                        featured_media {
                            localFile {
                                childImageSharp {
                                    sizes(maxWidth: 500) {
                                        ...GatsbyImageSharpSizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const guides = data.allWordpressWpGuides.edges

    return (
        <section className="IndexHero relative has-divider-curve">
            <div className="container pb-5">
                <div className="row justify-content-center">
                    <div
                        className="col-xl-10 mb-1 text-center"
                        style={{
                            opacity: 0,
                            animation:
                                "scaleDown 1s cubic-bezier(0.215,0.61,0.355,1) forwards",
                        }}
                    >
                        <h1 className="display-3 mx-auto phablet:w-4-5 pt-xxxxs pb-md">
                            Come for the{" "}
                            <TextAccent textColorClassName="red">
                                latest
                            </TextAccent>{" "}
                            news.
                        </h1>
                        <h2 className="h3 font-weight-normal">
                            Only eHealth delivers expert opinions, guides, and
                            stories, to help you with your health insurance
                            journey.
                        </h2>
                        <SearchBarWrap>
                            <ArticleSearchBar />
                        </SearchBarWrap>
                    </div>
                </div>
            </div>
            <div className="container container--grid">
                <div className="container__row">
                    <MainGuides>
                        {guides.map(({ node: guide }) => {
                            const updatedGuide = {
                                ...guide,
                                slug: `guide/${guide.slug}`,
                            }

                            return (
                                <PostCard
                                    key={updatedGuide.wordpress_id}
                                    post={updatedGuide}
                                />
                            )
                        })}
                    </MainGuides>
                </div>
            </div>

            <DividerCurve color="green" />
        </section>
    )
}
