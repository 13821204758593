import React from "react"

export default ({ children, textColorClassName }) => (
    <span
        className={`relative${
            textColorClassName ? ` text-${textColorClassName}` : ""
        }`}
    >
        {children}
        <svg
            className="absolute fixed-bottom"
            width="100%"
            viewBox="0 0 119 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M113.292 4.07356c-18.9911-.43249-39.3447-2.2245-62.0954-1.41409-17.5597.6255-32.0136 1.90997-49.45728 4.35803-.82039.11513-1.60154.54315-1.71668-.27724-.11513-.82038.93-1.08759 1.75039-1.20272C19.3027 3.07742 32.3881 1.2681 50.0673.63834 72.9113-.17539 97.173.20812 116.172.64078c.828.01886 2.198.05263 2.164 1.55224-.023.99974-1.045 1.9716-5.044 1.88054z"
                fill="currentColor"
            ></path>
        </svg>
    </span>
)
