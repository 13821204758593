import React from "react"
import styled from "styled-components"

const Styles = styled.div`
    display: block;
    content: "";
    position: absolute;
    bottom: -5px;
    z-index: 10;
    width: 100vw;
    & svg {
        width: 100%;
    }
`

export default ({ color = "white" }) => (
    <Styles className={`text-${color}`}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 40">
            <path
                d="M0 30.013C239.659 10.004 479.143 0 718.453 0S1198.28 10.004 1440 30.013V40H0v-9.987z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    </Styles>
)
