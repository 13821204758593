const meta = {
	// Metadata
	// Finds usage in gatsby-config, i18n, and SEO component
	siteTitle: "Health Insurance Resource Center - eHealth Insurance",
	siteDescription: `Confused about health insurance coverage? Get informed about different health insurance plans & Obamacare from eHealth's Resource Center today.`,
	siteHeadline: "Free Resources and guides for health insurance",
	siteTitleAlt: "eHealth Insurance",
	siteShortName: "eHealth Insurance",
	siteUrl: `https://www.${process.env.GATSBY_SUBDOMAIN || ''}ehealthinsurance.com/resources`, // No trailing slash!
}

const social = {
	siteLogo: `${meta.siteUrl}/social/avatar.png`,
	siteLogoSmall: `${meta.siteUrl}/social/avatar_small.png`,
	siteBanner: `${meta.siteUrl}/social/banner_`, // Locale ending + filetype gets added in SEO component
	siteBannerWidth: "776",
	siteBannerHeight: "382",
	twitter: "@eHealth",
	facebook: "eHealth",
}

const website = {
	...meta,
	...social,
	// googleAnalyticsID: "UA-47519312-1",

	// Manifest
	themeColor: "#7fbd01",
	backgroundColor: "#f4f4f4",
}

module.exports = website