import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { truncateString, getCookie } from "../../utils/helpers"

const Form = styled.form`
    flex-direction: column;

    .form-group,
    input {
        width: 100%;
    }
    .button-primary {
        margin-top: var(--space-md);
    }
    @media screen and (min-width: 400px) {
        flex-direction: row;
        align-items: flex-end;

        .form-group,
        input {
            width: auto;
        }
        .button-primary {
            margin-top: 0;
            margin-left: var(--space-xs);
        }
    }
`
export default ({ censusPage, fromPage }) => {
    const [zipCode, setZipCode] = useState()
    const [county, setCounty] = useState()
    const [isZipCodeError, setIsZipCodeError] = useState(false)

    const validateZipCode = e => {
        e.target.value = e.target.value.slice(0, 5)
        setZipCode(e.target.value)

        if (e.target.value > 9999 || `${e.target.value}` === "00000") {
            const zipCodeAPI = `https://www.${process.env.GATSBY_SUBDOMAIN ||
                ""}ehealthinsurance.com/ifp-api/misc/zip/zipInfo?zips=${
                e.target.value
            }`
            axios.get(zipCodeAPI).then(response => {
                const resultObj = response.data[0]

                if (resultObj.stateAbbr && resultObj.states) {
                    setIsZipCodeError(false)
                    setCounty(resultObj.counties[0])
                } else {
                    setIsZipCodeError(true)
                }
            })
        }
    }

    const handleSubmit = e => {
        e.preventDefault()

        if (!zipCode || zipCode.length < 5 || zipCode === "00000") return
        if (!isZipCodeError) {
            const quoteZipCode = zipCode ? zipCode : ""
            const allId = getCookie("ehi.alliance_id")
            const redirectURLs = {
                "Individual and Family": `https://www.${process.env
                    .GATSBY_SUBDOMAIN ||
                    ""}ehealthinsurance.com/individual-family-health-insurance?zipCode=${quoteZipCode}${
                    allId ? `&allid=${allId}` : ""
                }`,
                "Small Business": `https://www.${process.env.GATSBY_SUBDOMAIN ||
                    ""}ehealthinsurance.com/small-business-health-insurance/group-health-insurance-plans?zipCode=${quoteZipCode}${
                    allId ? `&allid=${allId}` : ""
                }`,
                "Medicare": `https://www.${process.env.GATSBY_SUBDOMAIN ||
                    ""}ehealthmedicareplans.com/v2?zip=${quoteZipCode}
                `,
                "Coronavirus Updates and Resources": `https://www.${process.env
                    .GATSBY_SUBDOMAIN ||
                    ""}ehealthinsurance.com${allId ? `?allid=${allId}` : ""
                }`,
            }
            window._waEvents.push("trackEvent", {
                page: { action: `${truncateString(fromPage, 50)}:find plans` },
                zipCode,
                county,
                events: ["find plans"],
            })
            window.location.href = redirectURLs[censusPage]
        }
    }

    return (
        <Form
            className="quote-form d-flex justify-content-center justify-content-md-start align-items-end mb-4"
            onSubmit={e => handleSubmit(e)}
            autoComplete="off"
        >
            <div
                className={`form-group mb-0 ${
                    isZipCodeError ? " is-error" : ""
                }`}
            >
                <label className="text-left" htmlFor="zipCode">
                    Zip code
                </label>
                <input
                    id="zipCode"
                    type="number"
                    onInput={e => validateZipCode(e)}
                />
                {isZipCodeError && (
                    <div className="error-message">
                        Typo? Zip code is not valid.
                    </div>
                )}
            </div>
            <input
                type="submit"
                className="button-primary"
                value="Find Plans"
            />
        </Form>
    )
}
